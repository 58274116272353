// extracted by mini-css-extract-plugin
export var locationWrapper = "location-module--locationWrapper--k8zvi";
export var breadcrumbsWrapper = "location-module--breadcrumbsWrapper--8JLbB";
export var locationIntroWrapper = "location-module--locationIntroWrapper--L1wuF";
export var kayakLogo = "location-module--kayakLogo--8--kM";
export var headingWrapper = "location-module--headingWrapper--kNJsS";
export var headerTitleText = "location-module--headerTitleText--3a5vi typography-module--title2--bj0Xz";
export var mainTitleText = "location-module--mainTitleText--d4lt1 typography-module--title1--oWEwE";
export var subTitleText = "location-module--subTitleText--jLxrL typography-module--large--y+p-f";
export var icon = "location-module--icon--x9lrj";
export var phoneLink = "location-module--phoneLink--AysAk";
export var emailLink = "location-module--emailLink--EHmHe";
export var infoWrapper = "location-module--infoWrapper--8ljpY";
export var iconWrapper = "location-module--iconWrapper--RMgkI";
export var descriptionWrapper = "location-module--descriptionWrapper--qnJWj";
export var logoIcon = "location-module--logoIcon--YJ8DA";
export var callDemoWrapper = "location-module--callDemoWrapper--nPVPP";
export var callDemoContent = "location-module--callDemoContent--U-IgE";
export var buttonCall = "location-module--buttonCall--frLl0";