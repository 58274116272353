import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import clientConfig from '../../client-config';
import BlockContent from '../components/block-content';
import GraphQLErrorList from '../components/graphql-error-list';
import Container from '../components/layout/container';
import SEO from '../components/layout/seo';
import ContentSections from '../components/pagebuilder/content-sections';
import Navigation from '../components/sections/cycle/Navigation';
import {
  kayakLogo,
  buttonCall,
  callDemoContent,
  callDemoWrapper,
  descriptionWrapper,
  emailLink,
  headerTitleText,
  headingWrapper,
  icon,
  iconWrapper,
  infoWrapper,
  locationWrapper,
  logoIcon,
  mainTitleText,
  phoneLink,
  subTitleText,
  locationIntroWrapper,
  breadcrumbsWrapper,
} from './location.module.css';
import { Breadcrumbs } from '@material-ui/core';

export const query = graphql`
  query LocationTemplateQuery($id: String!) {
    page: sanityLocation(id: { eq: $id }) {
      ...Location
    }
  }
`;

const LocationTemplate = (props) => {
  const {
    data: { page },
    errors,
  } = props;
  const {
    title,
    seo,
    seoImage,
    mainTitle,
    mapLink,
    _rawCallForDemo,
    _rawDescription,
    headerTitle,
    subTitle,
    openHours,
    phone,
    state,
    zip,
    city,
    address,
    email,
    heroImage,
    logo,
  } = page;

  const seoDescription = seo?.meta_description;
  const pageTitle = title || 'Untitled';
  const seoTitle = seo?.seo_title;

  const mainImg =
    heroImage && getGatsbyImageData(heroImage.image, { layout: 'constrained', maxWidth: 675 }, clientConfig.sanity);
  const logoImg = logo && getGatsbyImageData(logo.image, { layout: 'constrained', maxWidth: 675 }, clientConfig.sanity);

  return (
    <>
      <Navigation />
      {errors && <SEO title="GraphQL Error" />}
      {page && (
        <SEO
          title={pageTitle}
          seoImage={seoImage?.asset?.img?.src}
          seoTitle={seoTitle}
          description={seoDescription}
          keywords={page.seoKeywords}
          path={props.location.pathname}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <Container>
        <div className={locationWrapper}>
          <div className={breadcrumbsWrapper}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/locations">Locations</Link>
              <span>{title}</span>
            </Breadcrumbs>
          </div>
          <div className={locationIntroWrapper}>
            <div>
              <div className={headingWrapper}>
                {headerTitle && <h2 className={headerTitleText}>{headerTitle}</h2>}
                {mainTitle && <h1 className={mainTitleText}>{mainTitle}</h1>}
                {subTitle && <h4 className={subTitleText}>{subTitle}</h4>}
              </div>
              <div className={infoWrapper}>
                {phone && (
                  <a href={`tel:${phone}`} className={phoneLink}>
                    <PhoneEnabledIcon className={icon} />
                    {phone}
                  </a>
                )}
                {email && (
                  <a href={`mailto:${email}`} className={emailLink}>
                    <EmailIcon className={icon} />
                    {email}
                  </a>
                )}
                {mapLink && (
                  <a href={mapLink} target="_blank" className={iconWrapper}>
                    <LocationOnIcon className={icon} />
                    {address}
                    <br />
                    {city}, {state} {zip}
                  </a>
                )}
                {openHours && (
                  <div className={iconWrapper}>
                    <AccessTimeIcon className={icon} />
                    <span dangerouslySetInnerHTML={{ __html: openHours }} />
                  </div>
                )}
              </div>
              {_rawDescription && (
                <div className={descriptionWrapper}>
                  <BlockContent blocks={_rawDescription} />
                </div>
              )}
              {logo && (
                <div className={kayakLogo}>
                  <GatsbyImage image={logoImg} alt={logo.alt} className={logoIcon} />
                </div>
              )}
            </div>
            <div>
              <div>{heroImage && <GatsbyImage image={mainImg} alt={heroImage.alt} />}</div>
              {_rawCallForDemo && (
                <div className={callDemoWrapper}>
                  <div className={callDemoContent}>
                    <BlockContent blocks={_rawCallForDemo} />
                  </div>

                  {phone && (
                    <div>
                      <a href={`tel:${phone}`} className={buttonCall}>
                        Call For Demo
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {page && page.content && <ContentSections content={page.content} />}
      </Container>
    </>
  );
};

export default LocationTemplate;
